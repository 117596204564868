import React from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import PhoneIcon from "@material-ui/icons/Phone"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder"
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle"
import { makeStyles } from "@material-ui/core/styles"

const Info = ({ info }) => {
  const {
    streetAddress,
    cityStateZip,
    phone,
    googleMaps,
    doordash,
    postmates,
    grubhub,
    businessHours,
  } = info

  const classes = useStyles()
  return (
    <div className={classes.infoWrapper}>
      <div className={classes.infoContainer} id="contact-info">
        <div className={classes.infoTop}>
          <Typography variant="h5" className={classes.title}>
            REACH US AT
          </Typography>
        </div>
        <div className={classes.infoBottom}>
          <div className={`${classes.infoBottomItem} ${classes.icon}`}>
            <LocationOnIcon />
            <Typography variant="body1" className={classes.text}>
              {streetAddress}
              <br />
              {cityStateZip}
            </Typography>

            <Button
              href={googleMaps}
              size="medium"
              color="primary"
              variant="outlined"
              className={classes.button}
            >
              Get Directions
            </Button>
          </div>
          <div className={`${classes.infoBottomItem} ${classes.icon}`}>
            <PhoneIcon />
            <Typography variant="body1" className={classes.text}>
              {phone}
            </Typography>
            <Button
              href={`tel:${phone.replace(/[^0-9]/g, "")}`}
              size="medium"
              color="primary"
              variant="outlined"
              className={classes.button}
            >
              Give us a call
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.infoTop}>
          <Typography variant="h5" className={classes.title}>
            OPEN HOURS
          </Typography>
        </div>
        <div className={classes.icon}>
          <QueryBuilderIcon />
        </div>
        <div
          className={`${classes.text} ${classes.openHrs}`}
          dangerouslySetInnerHTML={{
            __html: businessHours.childMarkdownRemark.html,
          }}
        />
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.infoTop}>
          <Typography variant="h5" className={classes.title}>
            WE OFFER DELIVERY THROUGH
          </Typography>
        </div>
        <div className={classes.icon}>
          <AirportShuttleIcon />
        </div>
        {doordash && (
          <div className={classes.deliveryBtnContainer} id="delivery">
            <Button
              target="_blank"
              href={doordash}
              size="medium"
              color="primary"
              variant="outlined"
              fullWidth={true}
              rel="noreferrer noopener"
            >
              doordash
            </Button>
          </div>
        )}
        {postmates && (
          <div className={classes.deliveryBtnContainer}>
            <Button
              target="_blank"
              href={postmates}
              size="medium"
              color="primary"
              variant="outlined"
              fullWidth={true}
              rel="noreferrer noopener"
            >
              postmates
            </Button>
          </div>
        )}
        {grubhub && (
          <div className={classes.deliveryBtnContainer}>
            <Button
              href={grubhub}
              target="_blank"
              size="medium"
              color="primary"
              variant="outlined"
              fullWidth={true}
              rel="noreferrer noopener"
            >
              grubhub
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  infoWrapper: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    alignItems: "flex-start",
    padding: "0px 20px",
    "@media (max-width: 520px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 10px",
    maxWidth: "450px",

    "@media (max-width: 930px)": {
      maxWidth: "none",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 20px 0px",
      maxWidth: "450px",
    },
  },
  infoTop: {
    paddingBottom: 3,
  },
  title: {
    textAlign: "center",
    fontWeight: 400,
  },
  icon: {
    textAlign: "center",
  },
  button: {
    marginTop: "4px",
  },
  text: {
    fontWeight: 300,
  },
  infoBottom: {
    display: "flex",
    flexDirection: "column",
    padding: "3px 0px",
    alignItems: "center",
  },
  infoBottomItem: {
    textAlign: "center",
    padding: "0px 0px 10px 0px",
  },
  openHrs: {
    paddingBottom: "10px",
    fontFamily: "Montserrat,sans-serif",
  },
  deliveryBtnContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "3px 8px",
  },
}))

export default Info

Info.propTypes = {
  info: PropTypes.object.isRequired,
}
