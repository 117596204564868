import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Rating from "./Rating"

const SelectedCategory = ({ mainMenu, flavors, category, resturant }) => {
  const classes = useStyles()

  const sauceFlavors = flavors.map(
    ({ name, description, spice_level }, index) => {
      return (
        <div
          className={classes.flavorContainer}
          key={`${resturant}${flavors}${index}`}
        >
          <span className={classes.spice}>
            <Typography variant="body1">{name}</Typography>
            <Rating rating={spice_level} />
          </span>

          <Typography variant="body1" className={classes.fontItalic}>
            {description}
          </Typography>
        </div>
      )
    }
  )

  const menu = Object.keys(mainMenu).map((key, index) => {
    const items = mainMenu[key].map(
      ({ name, description, price, boneless, bone_in, deal }, index) => {
        return (
          <div
            className={
              price
                ? `${classes.menuItemContainer}`
                : `${classes.menuBBItemContainer}`
            }
            key={`${resturant}menu${index}`}
          >
            <div className={classes.menuItem}>
              <Typography variant="body1">{name}</Typography>

              <div>
                {description && (
                  <Typography variant="body1" className={classes.fontItalic}>
                    {description}
                  </Typography>
                )}
              </div>
            </div>
            {deal && <Typography variant="body1">{deal}</Typography>}
            {price ? (
              <Typography variant="body1">{price}</Typography>
            ) : (
              <div className={classes.priceContainer}>
                {boneless && (
                  <Typography variant="body1">
                    <span>boneless:&nbsp;</span>
                    {boneless}
                  </Typography>
                )}
                {bone_in && (
                  <Typography variant="body1">
                    <span>bone in:&nbsp;</span>
                    {bone_in}
                  </Typography>
                )}
              </div>
            )}
          </div>
        )
      }
    )
    return (
      <div className={classes.menuItems} key={`${key}${index}`}>
        <div className={classes.menuItemsTitle}>
          <Typography variant="body1">{key.replace(/_/g, " ")}</Typography>
        </div>
        <div className={classes.menuItemsList}>{items}</div>
      </div>
    )
  })

  return (
    <Fragment>
      <Typography className={classes.menuTitle}>{category}</Typography>
      <div className={classes.menuContainer}>
        {category !== "Sandwiches" && (
          <div className={classes.mainMenuCategory}>
            <Typography className={classes.menuFlavorsTitle}>
              Flavors
            </Typography>
            {sauceFlavors}
          </div>
        )}
        <div className={classes.otherMenuCategory}>
          <Typography className={classes.menuCategoryTitle}>
            {category === "Wings" && resturant === "Boom Chicken"
              ? "Wings : Classic or Bonelesss"
              : category}
          </Typography>
          {menu}
        </div>
      </div>
    </Fragment>
  )
}

export default SelectedCategory

SelectedCategory.propTypes = {
  category: PropTypes.string.isRequired,
  mainMenu: PropTypes.object.isRequired,
  flavors: PropTypes.array.isRequired,
  resturant: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  menuTitle: {
    display: "none",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "500",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      flexDirection: "column-reverse",
      paddingTop: "10px",
    },
  },
  menuContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px 20px 30px 20px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  mainMenuCategory: {
    display: "flex",
    flexDirection: "column",
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  menuFlavorsTitle: {
    color: "#f44336",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("xs")]: {
      color: "black",
      fontWeight: "500",
      alignSelf: "center",
      margin: "10px 0px",
    },
  },

  flavorContainer: {
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
    paddingBottom: "0.5rem",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  spice: {
    display: "flex",
    alignItems: "center",
    " & p": {
      paddingRight: "0.5rem",
      fontWeight: "500",
    },
  },

  otherMenuCategory: {
    display: "flex",
    flexDirection: "column",
  },

  menuCategoryTitle: {
    color: "#f44336",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  menuItems: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 0px 16px 0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  menuItemsTitle: {
    width: 134,
    paddingRight: "0.5rem",
    color: "#f44336",
    textTransform: "uppercase",
    "& p": {
      fontWeight: "400",
    },
  },
  menuItemsList: {
    display: "flex",
    flexDirection: "column",
  },

  menuItemContainer: {
    display: "flex",
    " & p": {
      fontWeight: "500",
    },
  },
  menuBBItemContainer: {
    display: "flex",
    flexDirection: "column",
    " & p": {
      fontWeight: "500",
    },
  },

  menuItem: {
    display: "flex",
    "& p": {
      paddingRight: "1rem",
      fontWeight: "500",
    },
  },

  priceContainer: {
    display: "flex",
    "& p": {
      fontWeight: "500",
      paddingRight: "1rem",
      paddingBottom: "0.5rem",
    },
  },

  fontItalic: {
    fontWeight: "300 !important",
    fontStyle: "italic",
  },
}))
