import React from "react"
import PropTypes from "prop-types"
import HalfIcon from "../images/icons/half.svg"
import WholeIcon from "../images/icons/whole.svg"
import { makeStyles } from "@material-ui/core/styles"

const Rating = ({ rating }) => {
  const classes = useStyles()
  const ratings = []

  for (let i = rating; i > 0; i -= 1) {
    if (i === 0.5) {
      ratings.push(
        <img
          key={`rating${i}`}
          alt="fire"
          src={HalfIcon}
          width="8px"
          height="16px"
        />
      )
    } else {
      ratings.push(
        <img
          key={`rating${i}`}
          alt="fire"
          src={WholeIcon}
          width="16px"
          height="16px"
        />
      )
    }
  }

  return <div className={classes.rating}>{ratings}</div>
}

export default Rating

Rating.propTypes = {
  rating: PropTypes.string.isRequired,
}

const useStyles = makeStyles(() => ({
  rating: {
    display: "flex",
  },
}))
