import json2mq from "json2mq"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export const MediaQuery = minWidth => {
  const matches = useMediaQuery(
    json2mq({
      minWidth: minWidth,
    })
  )
  return matches
}

export const isBoomBoomPage = string => {
  const regex = /boomboomchicken/g
  return string.match(regex)
}
