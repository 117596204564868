import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/Carousel.css"

const Carousel = ({ images }) => {
  const settings = {
    dots: true,
    speed: 500,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    fade: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  const classes = useStyles()
  const carouselData = images.photo
  const sliderImages = carouselData.map(({ id, fluid, title }) => (
    <Fragment key={id}>
      <Img fluid={fluid} alt={title} className={classes.sliderImg} />
    </Fragment>
  ))

  return (
    <Fragment>
      <Slider {...settings}>{sliderImages}</Slider>
    </Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  sliderImg: {
    maxHeight: "800px",
    width: "100vw",
    minWidth: 250,
    [theme.breakpoints.down("lg")]: {
      maxHeight: "750px",
    },
  },
}))

export default Carousel

Carousel.propTypes = {
  images: PropTypes.object.isRequired,
}
