import React from "react"

import { Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { MediaQuery } from "../utils/helpers"

const Banner = ({
  bannerTop,
  bannerLineFull,
  bannerMobile1,
  bannerMobile2,
}) => {
  const classes = useStyles()
  const isMobile = !MediaQuery(600)
  const text = (
    <>
      🔥🔥 {bannerTop} 🔥🔥
      <br /> {bannerLineFull}
    </>
  )
  const mobileText = (
    <>
      🔥 {bannerTop} 🔥
      <br /> {bannerMobile1}
      <br /> {bannerMobile2}
    </>
  )
  return (
    <div id="top">
      <Paper className={classes.banner} square elevation={0}>
        <Typography className={classes.typo}>
          {!isMobile ? text : mobileText}
        </Typography>
      </Paper>
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  button: {
    margin: 10,
    height: 25,
    background: "rgb(120, 169, 230)",
    border: "1px solid rgb(58, 148, 207)",
  },
  typo: {
    marginTop: 2,
    marginLeft: 10,
    background: "inherit",
    flexGrow: 1,
  },
  banner: {
    width: "100%",
    background: "#000",
    height: "60px",
    textAlign: "center",
    color: "#fff",
    display: " flex",
    fontWeight: "800 !important",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 620px)": {
      height: "75px",
      minWidth: "300px",
    },

    "@media (max-width: 330px)": {
      height: "150px",
      fontSize: "10px",
      minWidth: "250px",
    },
  },
}))

export default Banner
