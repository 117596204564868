import React, { useState } from "react"
import PropTypes from "prop-types"
import scrollTo from "gatsby-plugin-smoothscroll"
import SelectedCategory from "./SelectedCategory"
import OtherCategory from "./OtherCategory"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { MediaQuery } from "../utils/helpers"

const Menu = ({ flavors, main, sides, resturant }) => {
  const classes = useStyles()
  const [category, setCategory] = useState("Wings")
  const isMobile = !MediaQuery(600)

  let mainMenu
  let sideMenu
  const flavorsMenu = flavors.menu.flavors

  console.log("flavors", flavors)

  if (resturant === "Boom Chicken") {
    mainMenu = {
      Wings: main.menu.Wings,
      Tenders: main.menu.Tenders,
      Sandwiches: main.menu.Sandwiches,
    }
    sideMenu = {
      sides: sides.menu.sides,
      bomb_extras: sides.menu["bomb_extras"],
      drinks: sides.menu.drinks,
    }
  }

  if (resturant === "Boom Boom Chicken") {
    mainMenu = {
      Wings: main.menu.Wings,
      Tenders: main.menu.Tenders,
    }
    sideMenu = {
      sides: sides.menu.sides,
      bomb_extras: sides.menu["bomb_extras"],
      drinks: sides.menu.drinks,
    }
  }

  const handleSelect = category => {
    setCategory(category)
    if (isMobile) {
      scrollTo("#menu")
    }
  }

  return (
    <div className={classes.root} id="menu">
      <Typography variant="h5" className={classes.menuBarHeader}>
        MAIN MENU
      </Typography>
      <div className={classes.menuBarContainer}>
        <div className={classes.menuBarGroup}>
          <button
            aria-label={`select wings`}
            className={`${classes.button} ${
              category === "Wings" ? classes.selectedButton : ""
            }`}
            onClick={() => handleSelect("Wings")}
          >
            <span className={classes.buttonText}>Wings</span>
          </button>
          <button
            aria-label={`select tenders`}
            className={`${classes.button} ${
              category === "Tenders" ? classes.selectedButton : ""
            }`}
            onClick={() => handleSelect("Tenders")}
          >
            <span className={classes.buttonText}>Tenders</span>
          </button>
        </div>
        <div className={classes.menuBarGroup}>
          {resturant === "Boom Chicken" && (
            <button
              aria-label={`select sandwiches`}
              className={`${classes.button} ${
                category === "Sandwiches" ? classes.selectedButton : ""
              }`}
              onClick={() => handleSelect("Sandwiches")}
            >
              <span className={classes.buttonText}>Sandwiches</span>
            </button>
          )}

          <button
            onClick={() => handleSelect("Others & Sides")}
            aria-label={`select others & sides`}
            className={`${classes.button} ${
              category === "Others & Sides" ? classes.selectedButton : ""
            }`}
          >
            <span className={classes.buttonText}>Others & Sides</span>
          </button>
        </div>
      </div>
      {category !== "Others & Sides" ? (
        <SelectedCategory
          mainMenu={mainMenu[category]}
          flavors={flavorsMenu}
          category={category}
          resturant={resturant}
        />
      ) : (
        <OtherCategory category={category} sides={sideMenu} />
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "10px",
  },
  menuBarHeader: {
    textAlign: "center",
    fontWeight: 400,
  },
  menuBarContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      zIndex: 500,
    },
    //// Banner CSSs
    // "@media (max-width: 731px)": {
    //   position: "sticky",
    //   top: 158,
    //   zIndex: 500,
    // },

    // "@media (max-width: 600px)": {
    //   position: "sticky",
    //   top: 173,
    //   zIndex: 500,
    // },

    // "@media (max-width: 330px)": {
    //   position: "sticky",
    //   top: 248,
    //   zIndex: 500,
    // },
    ////// Banner CSS
  },
  menuBarGroup: {
    display: "flex",
    // margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "4px 0px",
      alignItems: "center",
    },
  },

  button: {
    color: "#f44336",
    cursor: "pointer",
    padding: "6px 10px",
  },
  buttonText: {
    textTransform: "uppercase",
    textDecoration: "none",
    fontSize: "1rem",
    textAlign: "center",
    borderBottom: "2px solid #f44336 !important",
    paddingBottom: 2,
    "&:hover, &:focus": {
      backgroundColor: "white",
      textDecoration: "none",
      color: "black",
    },
  },
  selectedButton: {
    backgroundColor: "white",
    color: "black",
  },
}))

export default Menu

Menu.propTypes = {
  flavors: PropTypes.object.isRequired,
  main: PropTypes.object.isRequired,
  sides: PropTypes.object.isRequired,
  resturant: PropTypes.string.isRequired,
}
