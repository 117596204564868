import React from "react"
import PropTypes from "prop-types"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const OtherCategory = ({ category, sides }) => {
  const classes = useStyles()

  const menu = Object.keys(sides).map((key, i) => {
    const items = sides[key].map(({ name, price }, i) => {
      return (
        <div className={classes.menuItem} key={`${name}${price}${i}`}>
          <Typography variant="body1" className={classes.name}>
            {name}
          </Typography>
          <Typography variant="body1">{price}</Typography>
        </div>
      )
    })
    return (
      <div className={classes.menuItems} key={`${key}${i}`}>
        <div className={classes.menuItemsTitle}>
          <Typography variant="body1">{key.replace(/_/g, " ")}</Typography>
        </div>
        <div className={classes.menuItemsList}>{items}</div>
      </div>
    )
  })

  return (
    <div className={classes.menuContainer}>
      <Typography className={classes.menuTitle}>{category}</Typography>
      <div className={classes.menu}>{menu}</div>
    </div>
  )
}

export default OtherCategory

OtherCategory.propTypes = {
  category: PropTypes.string.isRequired,
  sides: PropTypes.object.isRequired,
}

const useStyles = makeStyles(theme => ({
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px 30px 20px",
  },
  menuTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    color: "#f44336",
    fontWeight: "400",
    "@media (max-width: 600px)": {
      fontWeight: "500",
      flexDirection: "column",
      color: "#000",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "space-around",
    "@media (max-width: 780px)": {
      flexDirection: "column",
    },
  },
  menuItems: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  menuItemsTitle: {
    width: 130,
    color: "#f44336",
    textTransform: "uppercase",
    fontWeight: "500",
  },
  menuItemsList: {
    display: "flex",
    flexDirection: "column",
  },
  menuItem: {
    display: "flex",
  },
  name: {
    paddingRight: "0.5rem",
  },
}))
